<template>
  <div class="birthday-modal">
    <h4>Date of Birth</h4>
    <p class="big">PLEASE CONFIRM YOUR BIRTHDAY</p>
    <p class="small">
      TO KEEP OUR USERS SAFE, WE NEED TO VERIFY YOUR AGE FIRST
    </p>
    <b-row class="justify-content-center" no-gutters>
      <b-col cols="auto">
        <b-form-input
          class="input-1"
          type="number"
          min="1"
          max="12"
          placeholder="MM"
          v-model="birth.month"
          :formatter="formatMonth"
          pattern="[0-9]*"
          number
          inputmode="numeric"
          v-on:keypress="isNumber($event)"
        ></b-form-input>
      </b-col>

      <b-col cols="auto">
        <b-form-input
          class="input-1"
          type="number"
          placeholder="DD"
          min="1"
          :max="dayMax"
          v-model="birth.day"
          :formatter="formatDay"
          pattern="[0-9]*"
          number
          inputmode="numeric"
          v-on:keypress="isNumber($event)"
        ></b-form-input>
      </b-col>

      <b-col cols="4" lg="3" xl="3">
        <b-form-input
          class="input-2"
          type="number"
          placeholder="YYYY"
          v-model="birth.year"
          :formatter="formatYear"
          pattern="[0-9]*"
          :min="yearMin"
          :max="yearMax"
          number
          inputmode="numeric"
          v-on:keypress="isNumber($event)"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="justify-content-center">
      <b-col cols="6" lg="3" xl="3">
        <b-button
          class="btn-h3-orange mt-4"
          type="button"
          @click="setBirthdate"
          :disabled="$v.birth.$invalid || !isValidDate"
          block>
          Submit
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import { required, minLength, numeric, between }
    from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        birth : {
          month : null,
          day   : null,
          year  : null,
        },
        dayMax      : 31,
        yearMin     : 1900,
        yearMax     : new Date().getFullYear(),
        leapYear    : false,
        isValidDate : false,
      }
    },
    watch : {
      'birth.month'(val) {
        switch(parseInt(val)) {
        case 4:
        case 6:
        case 9:
        case 11:
          this.dayMax = 30;
          break;
        case 2:
          if (this.leapYear)
            this.dayMax = 29;
          else
            this.dayMax = 28;
          break;
        default:
          this.dayMax = 31;
          break;
        }

        if (parseInt(this.birth.day) &&
          (parseInt(this.birth.day) > this.dayMax))
          this.birth.day = null;

        if (val && this.birth.day && this.birth.year)
          this.checkDate();
      },
      'birth.day'(val) {
        if (val && this.birth.month && this.birth.year)
          this.checkDate();
      },
      'birth.year'(val) {
        if (val) {
          this.checkDate();
          this.leapYear = new Date(val, 1, 29).getDate() === 29;

          if (parseInt(this.birth.month) === 2) {
            if (this.leapYear)
              this.dayMax = 29;
            else {
              this.dayMax = 28;
              if (parseInt(this.birth.day) > this.dayMax)
                this.birth.day = null;
            }
          }
        }
      },
    },
    methods : {

      /**
       * Format Month field
       */
      formatMonth(e) {
        if (e) {
          const month = String(e).substring(0, 2);
          if (parseInt(month) > 12)
            return month.substring(0, 1);
          
          return month;
        }
      },

      /**
       * Format Day field
       */
      formatDay(e) {
        const day = String(e).substring(0, 2);
        if (parseInt(day) > this.dayMax)
          return day.substring(0, 1);

        return day;
      },

      /**
       * Format Year field
       */
      formatYear(e) {
        return String(e).substring(0, 4);
      },

      /**
       * Check if Date is valid
       */
      checkDate() {
        const partial = new Date(
          parseInt(this.birth.year), parseInt(this.birth.month) - 1,
          parseInt(this.birth.day));
        const birthday = moment(partial).format('YYYY-MM-DD');

        this.isValidDate = birthday === 'Invalid date' ? false : true;
      },

      /**
       * Set birthday localstorage value
       */
      setBirthdate() {
        const birthday = new Date(
          parseInt(this.birth.year), parseInt(this.birth.month) - 1,
          parseInt(this.birth.day));
        const formatted = moment(birthday).format('YYYY-MM-DD');
        localStorage.birthdate = formatted;

        // Get Age
        const ageDifMs = Date.now() - birthday.getTime();
        const ageDate = new Date(ageDifMs);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);

        if (age > 0) {
          localStorage.age = age;
          this.$emit('close');
        }
      },

      /**
       * Check if Number
       */
      isNumber(evt) {
        evt = (evt) ? evt : window.event;
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
          evt.preventDefault();
        else
          return true;
      },
    },
    validations() {
      return {
        birth : {
          month : { required, numeric },
          day   : { required, numeric },
          year  : {
            required,
            numeric,
            minLength : minLength(4),
            between   : between(this.yearMin, this.yearMax),
          },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .birthday-modal {
    margin: 1rem;
    padding: 3rem 2.5rem;
    max-width: 50rem;
    background-color: #342AFF;
    border: 8px solid #e7932d;
    border-radius: 20px;
    box-shadow: 0 -2px 20px rgba(0,0,0,0.5),
      0 15px 20px rgba(0,0,0,0.55);
    text-align: center;
  }

  h4 {
    margin-bottom: 1.5rem;
    -webkit-text-stroke: 1px $login-orange !important;
  }

  p {
    color: #fff;
    letter-spacing: 1.25px;
  }

  .big {
    margin-bottom: 0;
    font-family: 'WickedMouse' !important;
    font-size: 1.1em !important;
  }

  .small {
    font-family: 'WickedMouse' !important;
    font-size: 0.8em !important;
  }

  .input-1, .input-2 {
    border: 6px solid $login-orange;
    border-radius: 15px;
    color: $login-blue !important;
    font-family: 'WickedMouse' !important;
    font-size: 1.3em !important;
    text-align: center;
  }

  .input-1 {
    margin: 0 0.5rem;
    padding: 0;
    width: 3.5em;
    height: 3.5em;
  }

  .input-2 {
    margin: 0 0.5rem;
    padding: 0;
    height: 3.5em;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .birthday-modal {
      padding: 1.5rem 0.5rem;
      border: 5px solid $login-orange;
    }
    .input-1, .input-2 {
      margin: 0 0.25rem;
      padding: 0;
      border: 4px solid $login-orange;
      font-size: 1em !important;
    }
    .input-2 {
      width: 6em;
    }
    h4 {
      font-size: 1.3em !important;
    }
    .big {
      font-size: 1em !important;
    }
    .small {
      font-size: 0.75em !important;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .input-2 {
      width: 6em;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {}
  
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {}
  
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .birthday-modal {
      border: 6px solid $login-orange;
    }
    .input-1, .input-2 {
      height: 3.75em;
      border: 5px solid $login-orange;
    }
    .input-2 {
      width: 5.5em;
    }
  }
  @media only screen and (min-width: $special) {
    .birthday-modal {
      border: 6px solid $login-orange;
    }
    .input-1, .input-2 {
      height: 3.75em;
      border: 5px solid $login-orange;
    }
    .input-2 {
      width: 5.5em;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    .input-1, .input-2 {
      margin: 0 1rem;
      border: 6px solid $login-orange;
    }
  }
  
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .input-1 {
      margin: 0 1rem;
    }
  }
</style>